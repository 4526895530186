import React, { Component } from 'react'
import Layout from '../components/layout'

export default class About extends Component {
  render() {
    return (
      <Layout>
        <div className="ss-AboutBanner">
          <div className="ss-AppLayout">
            <span>All About</span>
            <span>Sokvanna</span>
          </div>
        </div>
        <section className="ss-AppLayout ss-AboutMe">
          <div className="ss-AboutCol">
            <h2>Intro</h2>
            <p>
              Welcome! I'm Sokvanna a developer here based in Tokyo, Japan.
              Interested in getting in contact with me? Shoot me an email at{' '}
              <a href="mailto:sokvannas@gmail.com">sokvannas@gmail.com</a>
            </p>
            <div>
              <h2>Experience</h2>
              <div className="ss-ExperienceCard">
                <h4>
                  Libra Inc. <span>(04/2016 - Present)</span>
                </h4>
                <i>React Developer / Mobile Developer / Content Creator</i>
                <p>
                  Responsible for a variety of different things in the company.
                </p>

                <ul>
                  <li>
                    <b>Front End Development of MagicalTrip travel site</b>{' '}
                    using React JS, Redux, Apollo Graphql.
                  </li>
                  <li>
                    <b>Mobile Development of MT Guide App</b> using Expo, React
                    Native, Apollo Graphql, Redux.
                  </li>
                  <li>
                    <b>
                      Director, storyboard, cameraman for multiple company brand
                      videos
                    </b>{' '}
                    using Adobe Premiere & After Effects & Camera.
                  </li>
                  <li>
                    <b>Create tour product posters and other creatives</b> using
                    Adobe Photoshop.
                  </li>
                  <li>
                    <b>Write Blog Articles for MagicalTrip media site</b> using
                    Ghost blog CMS.
                  </li>
                  <li>
                    <b>Modify Ghost Theme for media site</b> using Ghost blog
                    CMS and Handlebars JS.
                  </li>
                </ul>
              </div>

              <div className="ss-ExperienceCard">
                <h4>
                  IntuitSolutions <span>(10/2014 – 03/2016)</span>
                </h4>
                <i>Front End Developer</i>

                <p>
                  Responsible for translating existing designs into web using a
                  variety of coding techniques and managing clients via phone
                  and ticketing system.
                </p>

                <ul>
                  <li>
                    <b>Front End Development of Bigcommerce websites</b> using
                    HTML5 Canvas, CSS3, JavaScript, and jQuery.
                  </li>
                  <li>
                    <b>Implement responsive design</b> using media
                    queries/calculating percentages CSS3.
                  </li>
                  <li>
                    <b>API Integrations and customizations</b> using PHP, MYSQL,
                    and JavaScript.
                  </li>
                </ul>
              </div>

              <div className="ss-ExperienceCard">
                <h4>
                  Brownstein Group <span>(09/2012 – 03/2013)</span>
                </h4>
                <i>Junior Web Developer</i>

                <p>
                  Primarily responsible for assisting with translating design
                  layouts into optimized assets including valid HTML page
                  templates, HTML emails, and Flash banners.
                </p>

                <ul>
                  <li>
                    <b>Front End Development of IKEA Seasonal Catalog site</b>{' '}
                    using HTML, CSS3, JavaScript, and jQuery.
                  </li>
                  <li>
                    <b>Created HTML emails for e-blast campaigns</b> using
                    nested tables and media queries.
                  </li>
                  <li>
                    <b>Created Flash Banner Ads for Online Marketing</b> using
                    Flash timeline and AS3.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="ss-AboutCol">
            <div>
              <h2>Education</h2>

              <p>
                <span>Drexel University</span>
                <span>Bachelors of Science in Digital Media</span>
              </p>
              <p>
                <span>Central High School</span>
                <span>Bachelors of Arts Degree</span>
              </p>
            </div>

            <div>
              <h2>Skills</h2>

              <h4>Programming</h4>
              <p>
                HTML, CSS, SCSS, JavaScript, React JS, jQuery, Handlebars JS,
                PHP, Basic C++, Python, MYSQL
              </p>

              <h4>Frameworks</h4>
              <p>
                React Native, Wordpress, Ghost, Gatsby, Strapi, Bigcommerce,
                Django{' '}
              </p>

              <h4>Software</h4>
              <p>
                Adobe Creative Suite, Expo, ATOM, Visual Studio, Sublime,
                Netfily, Autodesk Maya, Autodesk 3ds Max
              </p>

              <h4>Languages</h4>
              <p>
                <span>English (Native)</span>
                <span>Khmer (Conversational)</span>
                <span>Japanese (Conversational)</span>
              </p>
            </div>

            <div>
              <h2>Honors</h2>
              <p>
                <span>Bachelors of Arts Degree</span>
                <span>Bachelors of Science Degree</span>
                <span>White Williams Scholar</span>
                <span>Benjamin Gilman Scholarship</span>
                <span>CIEE Japan Study Abroad Scholarship</span>
              </p>
            </div>

            <div />
          </div>
        </section>
      </Layout>
    )
  }
}
