import React, { Component } from 'react'
import { Link } from 'gatsby'
import Logo from '../images/SokLogo.png'
import Drawer from './drawer'

export default class Header extends Component {
  constructor() {
    super()
    this.state = {
      drawerOpen: false,
      shouldHeadFixed: false,
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll.bind(this))
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll.bind(this))
  }

  handleScroll(event) {
    const { shouldHeadFixed } = this.state
    if (window.scrollY > 300 && shouldHeadFixed !== true) {
      this.setState({ shouldHeadFixed: true })
    }
    if (window.scrollY < 300 && shouldHeadFixed !== false) {
      this.setState({ shouldHeadFixed: false })
    }
  }

  render() {
    return (
      <header className={this.state.shouldHeadFixed ? 'headerFixed' : null}>
        <div className="ss-TopHeader">
          <div className="ss-AppLayout">
            <div className="ss-Logo">
              <Link to="/">
                <img src={Logo} alt="logo" />
              </Link>
            </div>
            <nav className="deskNav">
              <Link to="/">Home</Link>
              <Link to="/about">About</Link>
              <Link to="/">Blog</Link>
            </nav>

            <Drawer />
          </div>
        </div>
      </header>
    )
  }
}
