import React from 'react'
import { Link } from 'gatsby'
import { MdMenu as HamburgerMenu } from 'react-icons/md'

export default function Drawer() {
  return (
    <nav className="ss-AppHeaderProductMenu-small">
      <div className="ss-HamburgerMenu">
        <a className="ui-AppHeader-Button">
          <HamburgerMenu size={24} />{' '}
          <span className="ss-x-wordHide">Menu</span>
        </a>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>

          <li>
            <Link to="/about">
              <span>About</span>
            </Link>
          </li>
          <li>
            <Link to="/">
              <span>Blog</span>
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  )
}
